import { AnalyticsPageMeta } from 'components/common';
import { usePageTitle } from 'hooks';
import React from 'react';

interface Props {
  title: string;
  pageName: string;
  customData?: object;
}

const PageInfo = ({ title, pageName, customData }: Props) => {
  usePageTitle(title);

  return <AnalyticsPageMeta pageName={pageName} customData={customData} />;
};

export default PageInfo;
