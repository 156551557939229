import { colors } from '@everlywell/leaves';
import PropTypes from 'prop-types';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { ariesTheme, theme } from 'utils/constants/styles';

import GlobalStyle from '../../../styles/global';
import Footer from './Footer';
import Header from './Header';
import * as S from './styles';

type Theme = typeof theme & typeof ariesTheme & { pageBackground?: string; };

interface Props {
  children: React.ReactNode;
  theme?: Partial<Theme>;
  isLandingPage?: boolean;
  customerLogo?: string;
  partnerName: string;
  footerInfo?: string;
}

const standardTheme = {
  pageBackground: colors.teal1,
}

const Layout = ({
  children,
  theme,
  isLandingPage = false,
  customerLogo,
  partnerName,
  footerInfo,
}: Props) => {
  const customTheme = { ...standardTheme, ...theme }

  return (
    <S.LayoutWrapper>
      <span data-portal-type="eligibility_file" hidden />
      <ThemeProvider theme={customTheme}>
        {!isLandingPage && (
          <Header
            customerLogo={customerLogo}
            partnerName={partnerName}
          />
        )}
        {children}
        <Footer
          customerLogo={customerLogo}
          partnerName={partnerName}
          info={footerInfo}
        />
      </ThemeProvider>
      <GlobalStyle theme={customTheme} />
    </S.LayoutWrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout
