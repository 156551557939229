import { SupportLink } from 'components/ui';
import React from 'react';

import NavLogos from '../NavLogos';
import * as S from './styles';

interface Props {
  customerLogo?: string;
  partnerName: string;
}

const Header = ({ customerLogo, partnerName }: Props) => {
  return (
    <S.Header>
      <NavLogos customerLogo={customerLogo} partnerName={partnerName} />
      <SupportLink />
    </S.Header>
  );
};

export default Header;
