import { mediaQueries, size, colors } from '@everlywell/leaves'
import { Link } from 'gatsby'
import styled from 'styled-components';

const Container = styled(Link)`
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: fit-content;
  z-index: 1;

  span {
    width: 1px;
    height: ${size.lg}px;
    margin-right: ${size.md}px;
    margin-left: ${size.md}px;
    background-color: ${colors.gray2};
  }

  > img {
    width: auto;
    height: ${size.lg}px;
  }

  ${mediaQueries.forTabletVerticalDown} {
    > img {
      width: auto;
      height: ${size.lg}px;
    }
  }
`

export {
  Container
}
