import { colors, size } from '@everlywell/leaves'
import styled from 'styled-components';

const Header = styled.header`
  display: flex;
  align-items: center;
  padding-right: ${size.xl1}px;
  padding-left: ${size.xl1}px;
  height: calc(${size.xl3}px + ${size.xs1}px);
  background-color: ${colors.white};
  box-shadow: inset 0px -2px 0px ${colors.teal1};

  [aria-label="Support"] {
    margin-left: auto;
  }
`

export { Header }
