import {
  size,
  colors,
  mediaQueries
} from "@everlywell/leaves"
import styled from "styled-components";

const Container = styled.div`
  max-width: 640px;
  margin: ${size.xl4}px auto;
  padding: ${size.xl2}px;
  border-radius: 2px;
  box-shadow: 0px 2px 32px -5px rgba(170, 169, 172, 0.24);
  background-color: ${colors.white};
  border-radius: 8px;

  h3 + p {
    margin-top: ${size.xs1}px;
  }

  ${mediaQueries.forTabletVerticalUp} {
    min-width: 640px;
  }

  ${mediaQueries.forTabletVerticalDown} {
    width: 100%;
    margin: 0;
    box-shadow: none;
    border-radius: 0;
  }
`

const Article = styled.article`
  font-size: 18px;
  font-weight: 300;
  color: ${colors.gray5};

  p + p {
    margin-top: ${size.lg}px;
  }
`

const OrderNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const OrderNumberHeading = styled.div`
  font-size: ${size.md}px;
  font-weight: 300;
`

const OrderNumber = styled.p`
  font-size: ${Number(size.md) + Number(size.xs2)}px;
  font-weight: 400;
`

const SupportLink = styled.a`
  color: ${colors.green4};
  text-decoration: none;

  &:hover {
    color: ${colors.green5};
    text-decoration: underline;
  }
`

export {
  Container,
  Article,
  OrderNumberContainer,
  OrderNumberHeading,
  OrderNumber,
  SupportLink,
}
