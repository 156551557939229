import { H2, size, Localize } from '@everlywell/leaves';
import { PageInfo } from 'components/eligibility-file/common/index';
import { ShipmentIcon } from 'components/icons';
import { Divider } from 'components/ui';
import React from 'react';
import { EF_MEMBERS_SUPPORT_FORM } from 'utils/constants';

import { Layout } from '../../common';
import * as S from './styles';

interface Props {
  customerLogo: string;
  partnerName: string;
  footerInfo?: string;
  firstName: string;
  orderNumber: string;
  memberId: string;
}

const OrderPlacedPage = ({
  customerLogo,
  partnerName,
  footerInfo,
  firstName,
  orderNumber,
  memberId,
}: Props) => {
  const formattedOrderNumer = `${orderNumber}`.slice(0, 9);

  return (
    <Layout
      customerLogo={customerLogo}
      partnerName={partnerName}
      footerInfo={footerInfo}
    >
      <S.Container>
        <PageInfo
          title="Order Placed"
          pageName="order - order placed"
          customData={{ order_number: orderNumber }}
        />

        <H2 style={{ textAlign: 'center' }} data-isolate>
          Thank you, <Localize name="first-name">{firstName}</Localize>!
        </H2>
        <Divider spacing={`${size.xs1}px`} isHidden />
        <S.OrderNumberContainer>
          <ShipmentIcon />
          <Divider spacing={`${size.xs1}px`} isHidden />
          <S.OrderNumberHeading>Order number:</S.OrderNumberHeading>
          <S.OrderNumber data-ignore>{formattedOrderNumer}</S.OrderNumber>
        </S.OrderNumberContainer>
        <Divider spacing={`${size.md}px`} isHidden />
        <S.Article>
          <p>
            Your order is now being processed, and you will receive your test in
            the mail soon. Once you collect your sample and your results have
            been processed, we will mail your results to you.
          </p>
          <p>
            If you have any questions about tracking your shipment, or would
            like to update your preferred shipping address, please contact the
            Everlywell Customer Care team by submitting the request form on{' '}
            <S.SupportLink href={`http://${EF_MEMBERS_SUPPORT_FORM}`}>
              {EF_MEMBERS_SUPPORT_FORM}
            </S.SupportLink>
            .
          </p>
        </S.Article>
      </S.Container>
    </Layout>
  );
};

export default OrderPlacedPage;
