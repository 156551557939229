import { SEO } from 'components/common';
import OrderPlacedPage from 'components/eligibility-file/pages/order-placed';
import { graphql, navigate } from 'gatsby';
import React, { useEffect } from 'react';

export const Head = () => <SEO title="Order Placed" />;

const OrderPlaced = ({ location, data }) => {
  const { customerLogo, partnerName, footerInfo } =
    data.contentfulOptInLandingPage;
  const shouldRedirect =
    !location?.state?.name ?? !location?.state?.orderNumber;

  useEffect(() => {
    if (shouldRedirect) {
      navigate('/');
    }
  }, [shouldRedirect]);

  if (shouldRedirect) return null;

  return (
    <OrderPlacedPage
      customerLogo={customerLogo?.gatsbyImageData?.images?.fallback?.src}
      partnerName={partnerName}
      footerInfo={footerInfo?.footerInfo}
      firstName={location?.state?.name}
      orderNumber={location?.state?.orderNumber}
      memberId={location?.state?.memberId}
    />
  );
};

export const query = graphql`
  query OrderPlacedPageQuery($brandId: String!) {
    contentfulOptInLandingPage(brandId: { eq: $brandId }) {
      partnerName
      customerLogo {
        gatsbyImageData
      }
      footerInfo {
        footerInfo
      }
    }
  }
`;

export default OrderPlaced;
