import { Tooltip, Localize, LocalizeDisabled } from '@everlywell/leaves';
import React from 'react';
import {
  APP_ROOT,
  EF_MEMBERS_SUPPORT_LINK,
  EF_MEMBERS_SUPPORT_EMAIL,
  EF_MEMBERS_SUPPORT_PHONE_NUMBER,
} from 'utils/constants';

import NavLogos from '../NavLogos';
import * as S from './styles';

interface Props {
  customerLogo?: string;
  partnerName: string;
  info?: string;
}

const Footer = ({ customerLogo, partnerName, info }: Props) => {
  const currentYear = new Date().getFullYear();

  return (
    <S.Footer>
      <S.FooterContainer>
        <NavLogos customerLogo={customerLogo} partnerName={partnerName} />
        <S.SupportNavLinks>
          <a
            href={EF_MEMBERS_SUPPORT_LINK}
            title={EF_MEMBERS_SUPPORT_LINK}
            target="_blank"
            rel="noreferrer"
          >
            Online help
          </a>
          <Tooltip
            tooltipBoxClass="phone-tooltip"
            // @ts-ignore
            content={
              <span>
                {EF_MEMBERS_SUPPORT_PHONE_NUMBER}{' '}
                <LocalizeDisabled>TTY: 711</LocalizeDisabled> Available <br />
                Monday - Friday <br />
                <LocalizeDisabled>8AM - 8PM EST</LocalizeDisabled>
              </span>
            }
          >
            <a href={`tel:${EF_MEMBERS_SUPPORT_PHONE_NUMBER}`}>Phone support</a>
          </Tooltip>
          <a
            href={`mailto:${EF_MEMBERS_SUPPORT_EMAIL}`}
            title={EF_MEMBERS_SUPPORT_EMAIL}
          >
            Email Everlywell
          </a>
        </S.SupportNavLinks>

        <S.FooterInfo>
          <div dangerouslySetInnerHTML={{ __html: info ?? '' }} />
        </S.FooterInfo>

        <S.FooterNavLinks>
          <a
            href={`${APP_ROOT}/privacy-policy/`}
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <a
            href={`${APP_ROOT}/terms-of-use/`}
            target="_blank"
            rel="noreferrer"
          >
            Terms of Use
          </a>
        </S.FooterNavLinks>
        <S.CopyrightText>
          © <Localize name="current-year">{currentYear}</Localize> Everlywell,
          Inc. All rights reserved.
        </S.CopyrightText>
      </S.FooterContainer>
    </S.Footer>
  );
};

export default Footer;
