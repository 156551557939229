import everlywellLogo from 'assets/images/everlywellLogo.svg';
import React from 'react';

import * as S from './styles';

interface Props {
  customerLogo?: string;
  partnerName: string;
}

const NavLogos = ({ customerLogo, partnerName }: Props) => {
  return (
    <S.Container to="/">
      <img
        src={customerLogo}
        alt={`${partnerName} logo`}
        title={partnerName}
        data-ignore
      />
      <span />
      <img
        src={everlywellLogo}
        alt="Everlywell logo"
        title="Everlywell"
        data-ignore
      />
    </S.Container>
  );
};

export default NavLogos;
