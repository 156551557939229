import { colors, size, Container, mediaQueries } from '@everlywell/leaves'
import styled from 'styled-components';

const Footer = styled.footer`
  margin-top: auto;
  padding-top: ${size.xl1}px;
  padding-bottom: ${size.xl1}px;
  font-size: 18px;
  font-weight: 400;
  color: ${colors.gray4};
  background-color: ${colors.white};
  box-shadow: 0px -2px 0px ${colors.green2};

  .phone-tooltip {
    min-width: 280px;
  }
`

const FooterContainer = styled(Container)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: ${size.xl2}px ${size.lg}px;

  ${mediaQueries.forTabletVerticalDown} {
    display: flex;
    flex-direction: column;
  }
`

const FooterInfo = styled.div`
  max-width: 50%;
  grid-column: 1 / span 2;

  a {
    color: ${colors.green4};
    text-decoration: none;

    &:hover,
    &:focus {
      color: ${colors.green5};
      text-decoration: underline;
    }
  }

  ${mediaQueries.forTabletVerticalDown} {
    max-width: max-content;
  }
`

const FooterNavLinks = styled.nav`
  display: flex;
  gap: ${size.md}px ${size.xl1}px;
  flex-wrap: wrap;
  align-items: center;

  a {
    color: currentColor;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  ${mediaQueries.forTabletVerticalDown} {
    justify-content: center;
  }
`

const flexRightAlignedStyle = `
  margin-left: auto;

  ${mediaQueries.forTabletVerticalDown} {
    margin-left: initial;
  }
`

const SupportNavLinks = styled(FooterNavLinks)`
  ${flexRightAlignedStyle};
`

const CopyrightText = styled.p`
  ${flexRightAlignedStyle};
`

export {
  Footer,
  FooterContainer,
  FooterNavLinks,
  FooterInfo,
  SupportNavLinks,
  CopyrightText,
}
